// packages
import axios from "axios";
import _ from "lodash";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

// assets
import BannerCatalog from "../../assets/images/banners/catalog.avif";

// classes
// import Diamond from "../../shared/classes/Diamond";
import Jewelry from "../../shared/classes/Jewelry";

// components (template)
// import Promos from "../../components/Promos";
import Categories from "../../components/Categories";
import Header from "../../components/Header";
import ImageBanner from "../../components/ImageBanner";
import IntroComponent from "../../components/IntroComponent";
import Slider from "../../components/Slider";
import SliderWithText from "../../components/SliderWithText";
import StandardContainer from "../../components/StandardContainer";

// data
import { categories } from "../../data/categories";
import { data as jewelrySliderData } from "../../data/components/jewelrySlider";

export default function HomeA() {
  const [featuredItems, setFeaturedItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFeaturedItems = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/companies/${process.env.REACT_APP_COMPANY_ID}/featured-items.json`,
          {
            headers: {
              Authorization: localStorage.token,
            },
          },
        );
        const rawData = response.data;
        let data = {};
        // data.diamonds = rawData.diamonds.map(
        //   (item) => new Diamond(item, "/diamonds"),
        // );
        data.jewelry = rawData.jewelry.map(
          (item) => new Jewelry(item, "/jewelry"),
        );
        setFeaturedItems(data);
      } catch (error) {
        console.error("Error fetching featured items:", error);
      }
    };

    fetchFeaturedItems();

    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const categorySections =
    featuredItems &&
    Object.keys(featuredItems).map((category, idx) => {
      return (
        <div
          key={idx}
          className="py-20"
        >
          <StandardContainer
            content={
              <>
                <NavLink
                  className="text-t-gray"
                  exact="exact"
                  to={`/${category}`}
                >
                  <Header
                    title={_.capitalize(category)}
                    topBorder={true}
                    bottomBorder={false}
                  />
                  {/* <div className="mb-4 xl:mb-8">
                    <ImageBanner image={categories[category].banner} />
                  </div> */}
                </NavLink>
                <div className="flex flex-col gap-4 md:gap-8">
                  <Categories
                    data={categories[category].categories}
                    imageCssClasses={categories[category].imageCssClasses}
                    path={categories[category].path}
                    param={categories[category].param}
                  />
                  <div className="mt-4 md:mt-6">
                    {jewelrySliderData.map((item, idx) => (
                      <SliderWithText
                        key={idx}
                        {...item}
                        reverse={idx % 2 === 1}
                      />
                    ))}
                  </div>
                  {/* <ItemGrid
                    data={featuredItems[category]}
                    limit={8}
                  /> */}
                </div>
              </>
            }
          />
        </div>
      );
    });

  const catalogSection = (
    <StandardContainer
      content={
        <NavLink
          className="text-t-gray"
          exact="exact"
          to="/catalog"
        >
          <Header
            title="Catalog"
            topBorder={true}
            bottomBorder={false}
          />
          <ImageBanner image={BannerCatalog} />
        </NavLink>
      }
    />
  );

  if (loading) {
    return <IntroComponent />;
  }

  return (
    <>
      <Slider />
      {categorySections}
      {catalogSection}
      {/* <Promos /> */}
      <div className="mb-4 sm:mb-8 xl:mb-12" />
    </>
  );
}
