// packages
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// classes
import Diamond from "../shared/classes/Diamond";
import Jewelry from "../shared/classes/Jewelry";

// components (shared)
import LoadingIndicator from "../shared/components/LoadingIndicator";

// components (template)
import AddToAny from "../components/item/AddToAny";
import ItemDetails from "../components/item/ItemDetails";
import ItemGallery from "../components/item/ItemGallery";
import SimilarItems from "../components/item/SimilarItems";
import StandardContainer from "../components/StandardContainer";

export default function Item({ type }) {
  //////////////////////////////////////////////////////////////////////////////
  // data
  //////////////////////////////////////////////////////////////////////////////

  const { id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [aside, setAside] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setAside(true);
      } else {
        setAside(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const apiUrl =
    type === "diamond"
      ? `${process.env.REACT_APP_API_URL}/stones/${id}.json`
      : `${process.env.REACT_APP_API_URL}/jewelries/${id}.json`;

  useEffect(() => {
    axios
      .get(apiUrl, {
        headers: {
          Authorization: localStorage.token,
        },
      })
      .then((response) => {
        type === "diamond"
          ? setData(new Diamond(response.data, "/diamonds"))
          : setData(new Jewelry(response.data, "/jewelry"));
      })
      .catch((error) => {
        console.error("Error fetching item:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  //////////////////////////////////////////////////////////////////////////////
  // UI
  //////////////////////////////////////////////////////////////////////////////

  const content = data && (
    <div className="flex flex-col gap-4 sm:gap-8 md:flex-row xl:gap-12">
      <main className="flex flex-col gap-4 sm:gap-8 md:w-3/5 xl:w-2/3">
        <ItemGallery data={data} />
        {!aside && (
          <aside>
            <ItemDetails dataAsParam={data} />
          </aside>
        )}
        {/* <OrderDetails data={data} /> */}
        <div className="mb-4 sm:mb-8">
          <SimilarItems
            type={type}
            id={data.id}
          />
        </div>
        {/* <Promos withoutContainer={true} /> */}
      </main>
      {aside && (
        <aside className="md:w-2/5 xl:w-1/3">
          <ItemDetails dataAsParam={data} />
        </aside>
      )}
    </div>
  );

  return loading ? (
    <LoadingIndicator message={`Loading ${type} details`} />
  ) : (
    <>
      {/* <Header title={data.title} /> */}
      <StandardContainer content={content} />
      <AddToAny />
    </>
  );
}
