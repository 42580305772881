// assets
import HeroImage from "../assets/images/heroes/contact.avif";

export const meta = {
  title: "Contact",
  description: "",
  keywords: [""],
};

export const hero = {
  image: {
    src: HeroImage,
    alt: "",
    width: 1000,
    height: 1000,
  },
  contents: [
    "Our dedicated team is here to assist you with any questions or inquiries. Whether you need guidance on purchasing fine diamond jewelry or have specific queries about our collections, our associates can provide expert assistance and personalized support.",
    "We value your feedback on your shopping experience with us. Your insights are essential in helping us improve our service, and we encourage you to share any comments or suggestions.",
    "We look forward to helping you find the perfect piece of jewelry.",
  ],
};

export const body = [
  {
    title: "Call",
    // contents: ["Weekdays: 9am–6pm", "Weekends: closed"],
    link: {
      href: "tel:+12136244400",
      text: "(213) 624-4400",
    },
  },
  {
    title: "Email",
    link: {
      href: "mailto:sales@ninacci.com",
      text: "Send A Message",
    },
  },
  {
    title: "Chat",
    link: {
      href: "https://wa.me/12136244400",
      text: "Start Chatting",
    },
  },
  {
    title: "Visit",
    link: {
      href: "https://maps.app.goo.gl/We2jGVNfv57A48oK6",
      text: "550 S Hill St, Suite #1101<br />Los Angeles, CA 90013",
    },
  },
  // {
  //   title: "New York",
  //   link: {
  //     href: "https://maps.app.goo.gl/XhDu6QkWvtbR4g3n8",
  //     text: "580 5th Ave, Suite #2600<br />New York, NY 10036",
  //   },
  // },
];
